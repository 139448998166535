
import Vue from "vue";
//@ts-ignore
import CookieLaw from "vue-cookie-law";

export default Vue.extend({
  name: "CookiesModal",
  components: {
    CookieLaw
  },
  props: {
    isAuthorized: Boolean
  }
});
